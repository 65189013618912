<template>
    <div>


        <div>

            <b-row style="background: white;">
                <!-- Search -->
                <b-col cols="12" md="2">
                    <h5>Amount from</h5>
                    <b-form-input v-model="amountFrom" />
                </b-col>
                <b-col cols="12" md="2">
                    <h5>Amount until</h5>
                    <b-form-input v-model="amountUntil" />
                </b-col>
                <b-col cols="12" md="2">
                    <b-form-group>
                        <h5>Invoice status</h5>
                        <v-select v-model="invoiceStatusSelected" label="title" :options="optionsInvoiceStatus"
                            class="select-size-sm" />
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="2">
                    <b-form-group>
                        <h5>Booking status</h5>
                        <v-select v-model="selectedBookingStatusCode" label="title" :options="optionsStatusCodes"
                            class="select-size-sm" />
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="2">
                    <b-form-group>
                        <h5>Confirmation #</h5>
                        <b-form-input v-model="booking_id" />
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="2" v-if="$store.getters['Users/userData'].user.role.id !== 3">
                    <b-form-group>
                        <h5>Company</h5>
                        <b-form-input v-model="corporateName" />
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="2">
                    <b-form-group>
                        <h5>Client</h5>
                        <b-form-input v-model="selfpayName" />
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="2">
                    <b-form-group>
                        <h5>Last4</h5>
                        <b-form-input v-model="last4" />
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                    <b-form-group>
                        <h5>Pickup</h5>
                        <flat-pickr v-model="rangeDate" class="form-control" :config="{ mode: 'range' }" />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12 text-right" class="mb-1">
                    <button class="btn btn-success mr-2" @click="clearFilter()">
                        Clear Filter
                    </button>
                    <button class="btn btn-primary" @click="getInvoicesByFilter('')">
                        Filter data
                    </button>
                </b-col>
            </b-row>

            <div style="overflow-x: scroll; min-height: 500px" v-if="totalItems > 0">
                <table small caption-top responsive style="font-size: small" id="table" class="table b-table">
                    <thead head-variant="dark">
                        <tr>
                            <th>Actions</th>
                            <th @click="getItemsSort('id')">Id invoice</th>
                            <th @click="getItemsSort('invoice_status')">Invoice Status</th>
                            <th>Extra charge Status</th>
                            <th @click="getItemsSort('payment_type')">Payment type</th>
                            <th @click="getItemsSort('created_at')">Create_at</th>
                            <th @click="getItemsSort('company')"
                                v-if="$store.getters['Users/userData'].user.role !== 3">
                                Company</th>
                            <th @click="getItemsSort('reference_number')">Reference number</th>
                            <th @click="getItemsSort('booking_id')">Confirmation number</th>
                            <th @click="getItemsSort('booking_status')">Booking Status</th>
                            <th @click="getItemsSort('pickup_date')">Pickup</th>
                            <th @click="getItemsSort('customer')">Customer</th>
                            <th @click="getItemsSort('driver_type')">Driver type</th>
                            <th @click="getItemsSort('driver_name')">Driver</th>
                            <th @click="getItemsSort('trip_category')">Trip Category</th>
                            <th @click="getItemsSort('pickup_address')">Pick-up Address</th>
                            <th @click="getItemsSort('city')">City</th>
                            <th @click="getItemsSort('state')">State</th>
                            <th @click="getItemsSort('dropoff_address')">Drop off address</th>
                            <th>Client Charges</th>
                            <th @click="getItemsSort('total_extra_charge')">Extra charges client</th>
                            <th @click="getItemsSort('total_extra_charge')">Other charges client</th>
                            <th @click="getItemsSort('booking_category')">Booking Category</th>
                            <th @click="getItemsSort('day')">Day</th>
                            <th @click="getItemsSort('month')">Month</th>
                            <th @click="getItemsSort('year')">Year</th>
                            <th @click="getItemsSort('po_rate')">Driver rate</th>
                            <th @click="getItemsSort('additional_charges_driver')">Additional charges driver</th>
                            <th @click="getItemsSort('po_payment')">Driver payment</th>
                            <th @click="getItemsSort('margin')">Margin%</th>
                            <th @click="getItemsSort('collab_user')">CC</th>
                            <th @click="getItemsSort('provider_relations')">PR</th>
                            <th @click="getItemsSort('pr_locked_date')">PR Locked date</th>
                            <th @click="getItemsSort('last4')">Last4</th>
                            <!-- <th>Stripe charge id</th> -->

                            <th>Actions</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in listItems" :key="item.id">
                            <td>
                                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL"
                                    transition="scale-transition" :offset-y="true">
                                    <template #button-content>
                                        <feather-icon icon="MoreVerticalIcon" size="16"
                                            class="align-middle text-body" />
                                    </template>
                                    <template v-slot:activator="{ on, attrs }">
                                        <b-btn color="primary" v-bind="attrs" v-on="on" icon ripple>
                                        </b-btn>
                                    </template>


                                    <b-list-group style="padding: 0px; margin-bottom: 0px" dense rounded>

                                        <template v-if="item.booking">
                                            <b-list-group-item style="padding: 0" v-if="item.booking" class="urlPagina"
                                                :ripple="false">
                                                <b-list-group-item @click="openUpdateBooking(item.booking_id)"
                                                    class="font-weight-bold"
                                                    style="border: none; padding: 0px; color: #7367f0">
                                                    <feather-icon icon="CheckIcon" />
                                                    Edit
                                                </b-list-group-item>
                                            </b-list-group-item>
                                        </template>


                                        <template
                                            v-if="$store.getters['Users/userData'].user.role !== 3 && item.booking">
                                            <b-list-group-item style="padding: 0"
                                                v-if="item.booking.is_charge_hold === 1 && item.invoice_status.toUpperCase() == 'PRE-AUTHORIZED' && item.amount > 0"
                                                class="urlPagina" :ripple="false">
                                                <b-list-group-item
                                                    @click="captureCharge(item.charge_id, item.booking_id)"
                                                    class="font-weight-bold"
                                                    style="border: none; padding: 0px; color: #7367f0">
                                                    <feather-icon icon="CheckIcon" />
                                                    Capture
                                                </b-list-group-item>
                                            </b-list-group-item>


                                            <b-list-group-item style="padding: 0"
                                                v-if="(item.booking.is_charge_hold === 1 && item.invoice_status.toUpperCase() == 'PRE-AUTHORIZED')"
                                                class="urlPagina" :ripple="false">
                                                <b-list-group-item
                                                    @click="cancelCharge(item.charge_id, item.booking_id, item.id)"
                                                    class="font-weight-bold"
                                                    style="border: none; padding: 0px; color: #7367f0">
                                                    <feather-icon icon="CheckIcon" />
                                                    Cancel
                                                </b-list-group-item>
                                            </b-list-group-item>


                                            <b-list-group-item style="padding: 0" v-if="item.booking" class="urlPagina"
                                                :ripple="false">
                                                <b-list-group-item @click="openExtracharges(item.booking_id)"
                                                    class="font-weight-bold"
                                                    style="border: none; padding: 0px; color: #7367f0">
                                                    <feather-icon icon="CheckIcon" />
                                                    Extra charge
                                                </b-list-group-item>
                                            </b-list-group-item>


                                            <b-list-group-item style="padding: 0" v-if="item.booking" class="urlPagina"
                                                :ripple="false">
                                                <b-list-group-item @click="GetPdfInvoice(item.booking_id)"
                                                    class="font-weight-bold"
                                                    style="border: none; padding: 0px; color: #7367f0">
                                                    <feather-icon icon="CheckIcon" />
                                                    Print Invoice
                                                </b-list-group-item>
                                            </b-list-group-item>

                                            <b-list-group-item style="padding: 0" v-if="item.booking" class="urlPagina"
                                                :ripple="false">
                                                <b-list-group-item
                                                    @click="openModalEmailInvoice(item.booking_id, item.company)"
                                                    class="font-weight-bold"
                                                    style="border: none; padding: 0px; color: #7367f0">
                                                    <feather-icon icon="CheckIcon" />
                                                    Email Invoice
                                                </b-list-group-item>
                                            </b-list-group-item>

                                            <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false"
                                                v-if="item.payment_type == 'il' && item.invoice_status == ''">
                                                <b-list-group-item @click="changeStatusInvoiceLater(item.id, 1)"
                                                    class="font-weight-bold"
                                                    style="border: none; padding: 0px; color: #7367f0">
                                                    <feather-icon icon="CheckIcon" />
                                                    Set as paid
                                                </b-list-group-item>
                                            </b-list-group-item>


                                            <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false"
                                                v-if="item.payment_type == 'il' && item.invoice_status == 'paid'">
                                                <b-list-group-item @click="changeStatusInvoiceLater(item.id, 0)"
                                                    class="font-weight-bold"
                                                    style="border: none; padding: 0px; color: #7367f0">
                                                    <feather-icon icon="CheckIcon" />
                                                    Set as not paid
                                                </b-list-group-item>
                                            </b-list-group-item>


                                        </template>

                                    </b-list-group>


                                </b-dropdown>
                            </td>
                            <td> {{ item.id }}</td>
                            <td> {{ item.invoice_status.toUpperCase() }}</td>
                            <td> {{ getInvoiceExtraChargeStatus(item) }}</td>
                            <!-- PAID=0, PRE-AUTHORIZED=1, CHARGE EXPIRED=3, CANCELLED=4 -->
                            <td> {{ getPaymentType(item.payment_type) }}</td>
                            <td> {{ convertDate(item.created_at) }}</td>
                            <td v-if="$store.getters['Users/userData'].user.role !== 3"> {{ getCompany(item.company) }}
                            </td>
                            <td> {{ item.reference_number }}</td>
                            <td> {{ item.booking_id }}</td>
                            <td> {{ item.booking_status }}</td>
                            <td> {{ getDate(item.pickup_date) }}</td>
                            <td> {{ item.customer }}</td>
                            <td> {{ item.driver_type }}</td>
                            <td> {{ item.driver_name }}</td>
                            <td> {{ item.trip_category }}</td>
                            <td> {{ item.pickup_address }}</td>
                            <td> {{ item.city }}</td>
                            <td> {{ item.state }}</td>
                            <td> {{ item.dropoff_address }}</td>
                            <td> {{ calculateTotalInvoiceForClient(item) }}</td>
                            <td>{{ GetExtraCharge(item) }}</td>
                            <td>{{ item.additional_charges }}</td>
                            <td> {{ item.booking_category }}</td>
                            <td> {{ item.day }}</td>
                            <td> {{ item.month }}</td>
                            <td> {{ item.year }}</td>
                            <td> {{ item.po_rate }}</td>
                            <td> {{ item.additional_charges_driver }}</td>
                            <td> {{ item.po_payment }}</td>
                            <td> {{ item.margin }} </td>
                            <td> {{ getCollabUser(item.collab_user) }}</td>
                            <td> {{ getProviderRelations(item) }}</td>
                            <td> {{ getDate(item.pr_locked_date) }}</td>
                            <td> {{ item.last4 }} </td>
                            <td>

                                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL"
                                    transition="scale-transition" :offset-y="true">
                                    <template #button-content>
                                        <feather-icon icon="MoreVerticalIcon" size="16"
                                            class="align-middle text-body" />
                                    </template>
                                    <template v-slot:activator="{ on, attrs }">
                                        <b-btn color="primary" v-bind="attrs" v-on="on" icon ripple>
                                        </b-btn>
                                    </template>


                                    <b-list-group style="padding: 0px; margin-bottom: 0px" dense rounded>

                                        <template v-if="item.booking">
                                            <b-list-group-item style="padding: 0" v-if="item.booking" class="urlPagina"
                                                :ripple="false">
                                                <b-list-group-item @click="openUpdateBooking(item.booking_id)"
                                                    class="font-weight-bold"
                                                    style="border: none; padding: 0px; color: #7367f0">
                                                    <feather-icon icon="CheckIcon" />
                                                    Edit
                                                </b-list-group-item>
                                            </b-list-group-item>
                                        </template>


                                        <template
                                            v-if="$store.getters['Users/userData'].user.role !== 3 && item.booking">
                                            <b-list-group-item style="padding: 0"
                                                v-if="item.booking.is_charge_hold === 1 && item.invoice_status.toUpperCase() == 'PRE-AUTHORIZED' && item.amount > 0"
                                                class="urlPagina" :ripple="false">
                                                <b-list-group-item
                                                    @click="captureCharge(item.charge_id, item.booking_id)"
                                                    class="font-weight-bold"
                                                    style="border: none; padding: 0px; color: #7367f0">
                                                    <feather-icon icon="CheckIcon" />
                                                    Capture
                                                </b-list-group-item>
                                            </b-list-group-item>


                                            <b-list-group-item style="padding: 0"
                                                v-if="(item.booking.is_charge_hold === 1 && item.invoice_status.toUpperCase() == 'PRE-AUTHORIZED')"
                                                class="urlPagina" :ripple="false">
                                                <b-list-group-item
                                                    @click="cancelCharge(item.charge_id, item.booking_id, item.id)"
                                                    class="font-weight-bold"
                                                    style="border: none; padding: 0px; color: #7367f0">
                                                    <feather-icon icon="CheckIcon" />
                                                    Cancel
                                                </b-list-group-item>
                                            </b-list-group-item>


                                            <b-list-group-item style="padding: 0" v-if="item.booking" class="urlPagina"
                                                :ripple="false">
                                                <b-list-group-item @click="openExtracharges(item.booking_id)"
                                                    class="font-weight-bold"
                                                    style="border: none; padding: 0px; color: #7367f0">
                                                    <feather-icon icon="CheckIcon" />
                                                    Extra charge
                                                </b-list-group-item>
                                            </b-list-group-item>


                                            <b-list-group-item style="padding: 0" v-if="item.booking" class="urlPagina"
                                                :ripple="false">
                                                <b-list-group-item @click="GetPdfInvoice(item.booking_id)"
                                                    class="font-weight-bold"
                                                    style="border: none; padding: 0px; color: #7367f0">
                                                    <feather-icon icon="CheckIcon" />
                                                    Print Invoice
                                                </b-list-group-item>
                                            </b-list-group-item>

                                            <b-list-group-item style="padding: 0" v-if="item.booking" class="urlPagina"
                                                :ripple="false">
                                                <b-list-group-item
                                                    @click="openModalEmailInvoice(item.booking_id, item.company)"
                                                    class="font-weight-bold"
                                                    style="border: none; padding: 0px; color: #7367f0">
                                                    <feather-icon icon="CheckIcon" />
                                                    Email Invoice
                                                </b-list-group-item>
                                            </b-list-group-item>


                                            <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false"
                                                v-if="item.payment_type == 'il' && item.invoice_status == ''">
                                                <b-list-group-item @click="changeStatusInvoiceLater(item.id, 1)"
                                                    class="font-weight-bold"
                                                    style="border: none; padding: 0px; color: #7367f0">
                                                    <feather-icon icon="CheckIcon" />
                                                    Set as paid
                                                </b-list-group-item>
                                            </b-list-group-item>


                                            <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false"
                                                v-if="item.payment_type == 'il' && item.invoice_status == 'paid'">
                                                <b-list-group-item @click="changeStatusInvoiceLater(item.id, 0)"
                                                    class="font-weight-bold"
                                                    style="border: none; padding: 0px; color: #7367f0">
                                                    <feather-icon icon="CheckIcon" />
                                                    Set as not paid
                                                </b-list-group-item>
                                            </b-list-group-item>

                                        </template>

                                    </b-list-group>


                                </b-dropdown>

                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td colspan="10" variant="secondary" class="text-right">
                                Total Rows: <b>{{ totalItems }}</b>
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>


            <div class="pagination_block">
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center">
                        <li class="page-item" :class="{ disabled: pagination.prev_page_url == null }">
                            <button type="button" class="page-link"
                                @click="getItems(pagination.first_page_url)">&laquo;</button>
                        </li>
                        <li class="page-item" :class="{ disabled: pagination.prev_page_url == null }">
                            <button type="button" class="page-link"
                                @click="getItems(pagination.prev_page_url)">Previous</button>
                        </li>
                        <li class="page-item" v-if="current_page - 2 > 0">
                            <button type="button" class="page-link"
                                @click="getItems(pagination.path + '?page=' + (current_page - 2))">{{ current_page
                                    - 2
                                }}</button>
                        </li>
                        <li class="page-item" v-if="pagination.prev_page_url">
                            <button type="button" class="page-link" @click="getItems(pagination.prev_page_url)">{{
                                current_page - 1
                                }}</button>
                        </li>
                        <li class="page-item">
                            <button type="button" class="page-link"
                                :class="{ current: pagination.current_page == current_page }">{{
                                    current_page
                                }}</button>
                        </li>
                        <li class="page-item" v-if="pagination.next_page_url">
                            <button type="button" class="page-link" @click="getItems(pagination.next_page_url)">{{
                                current_page + 1
                                }}</button>
                        </li>
                        <li class="page-item" v-if="current_page + 2 <= last_page">
                            <button type="button" class="page-link"
                                @click="getItems(pagination.path + '?page=' + (current_page + 2))">{{ current_page
                                    + 2
                                }}</button>
                        </li>
                        <li class="page-item" :class="{ disabled: pagination.next_page_url == null }">
                            <button type="button" class="page-link"
                                @click="getItems(pagination.next_page_url)">Next</button>
                        </li>
                        <li class="page-item" :class="{ disabled: pagination.next_page_url == null }">
                            <button type="button" class="page-link"
                                @click="getItems(pagination.last_page_url)">&raquo;</button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <b-modal id="modal-extra-charges-information" centered hide-footer size="xl" style="">
            <div class="d-block text-center">
                <h2>Extra charge information</h2>
            </div>
            <DetailsExtraCharges v-if="currentBookingId != null" :bookingId="currentBookingId" class="mt-2 pt-75" />
            <div style="height: 20vh;"></div>
        </b-modal>
        <b-modal id="modal-extracharge" centered hide-footer size="xl">
            <DetailsExtraCharges v-if="showModalExtraCharge" :showTitleTicketNumber="true" :bookingId="bookingIdSeleted"
                class="mt-2 pt-75" />
        </b-modal>


        <b-modal id="modal-send-email-invoice" centered hide-footer size="sm" style="">
            <div class="d-block text-left">
                <h5>Ticket number: {{ seletedBookingId }}</h5>
                <p class="mt-2">Select who will receive the email:</p>
            </div>
            Customer
            <input type="checkbox" class="chkEmailInvoice" value="customer">
            <span class="ml-2">Company</span>
            <input type="checkbox" class="chkEmailInvoice" value="company">
            <br>
            <div>
                <b-button @click="sendEmailInvoice(seletedBookingId)" class="btn btn-success"
                    style="left: right;margin-top: 15px;margin-bottom:15px;float: right;" size='sm'>
                    Send Email
                </b-button>
            </div>

        </b-modal>

    </div>


</template>

<script>

import {GetInvoiceExtraChargeStatus} from "@core/utils/utils";
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, BListGroup, BListGroupItem,
    BFormSelect, BTableSimple, BThead, BTr, BTd, BTh, BTbody, BTfoot, BFormGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { getMessageError } from '@core/utils/utils'
import { convertToMMDDYYYY, convertToHHMM, convertToMMDDYYYYHHmm } from '@core/utils/dates'
// import UsersListFilters from './UsersListFilters.vue'
import UserListAddNew from '@core/components/infoClients/UserListAddNew'
import { selectedRow } from "@core/utils/cssStyle";
import DetailsExtraCharges from "@core/components/details-reservas/DetailsExtraCharges";
if (process.client) {
    window.io = require('socket.io-client')
}

export default {
    name: 'ListReservationToCancel',
    components: {
        UserListAddNew,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BListGroup,
        BListGroupItem,
        BFormSelect,
        BTableSimple,
        BThead, BTr, BTd, BTh, BTbody, BTfoot,
        BFormGroup,
        flatPickr,
        vSelect,
        DetailsExtraCharges,
    },
    watch: {
        currentUrl() {
            sessionStorage.setItem("currentUrl", JSON.stringify(this.currentUrl));
        }
    },
    data() {
        return {
            listItems: [],
            perPage: 10,
            currentPage: 1,
            totalItems: 0,
            pageOptions: [3, 5, 10],
            pagination: {}, current_page: 1, last_page: 0,
            fields: [{ label: 'Customer', key: 'name_selfpay', variant: 'info' }, { label: 'Status', key: 'status_code', tdClass: 'myclass' }, 'pickup_time', 'surgery_type', 'appoinment_datetime', 'city', 'actions'],
            amountFrom: null,
            amountUntil: null,
            invoiceStatusSelected: {
                value: ""
            },
            booking_id: null,
            corporateName: null,
            selfpayName: null,
            last4: null,
            rangeDate: null,
            columnOrder: 'booking_id',
            orderDesc: true,
            optionsInvoiceStatus: [
                { value: "Cancelled", title: "Cancelled" },
                { value: "Charge expired", title: "Charge expired" },
                { value: "PAID", title: "Paid" },
                { value: "PRE-AUTHORIZED", title: "Pre-authorized" },
                { value: "Needs to be pre-authorized", title: "Needs to be pre-authorized" },
            ],
            optionsStatusCodes: [],
            selectedBookingStatusCode: {},
            currentUrl: "",
            currentBookingId: null,
            bookingIdSeleted: null,
            showModalExtraCharge: false,
            seletedBookingId: 0,
        }
    },
    methods: {
        openExtracharges(bookingId) {
            this.showModalExtraCharge = false;
            this.showModalExtraCharge = true;
            this.bookingIdSeleted = bookingId;
            this.$bvModal.show("modal-extracharge");
        },
        convertDate(data) {
            return convertToMMDDYYYYHHmm(data)
        },
        convertTime(data) {
            return convertToHHMM(data)
        },
        getItems(url) {
            this.getInvoicesByFilter(url)
        },
        getCorporateAccount(item) {
            let res = ''
            try {
                if (item.corporate_account) {
                    res = item.corporate_account.company_legal_name.toUpperCase()
                }
            }
            catch (err) {
            }

            return res
        },
        getInvoicesByFilter(url) {
            if (url == null) {
                url = '';
            }
            this.setValuesFilter();
            this.$swal({
                title: 'Please, wait...',
                didOpen: () => {
                    this.$swal.showLoading()
                },
            })

            let data = this.getValuesParameters()

            if (url.length === 0) {
                if (this.$store.getters["Users/userData"].user.role === 3) {
                    url = `ca/panel/corporate/invoice/get_invoice_byFilter`
                } else {
                    url = `admin/panel/invoice/get_invoice_byFilter`
                }
            }

            this.currentUrl = url;

            this.$http.post(url, data).then((response) => {
                this.listItems = response.data.data.data;
                this.totalItems = response.data.data.total;
                this.current_page = response.data.data.current_page
                this.last_page = response.data.data.last_page
                this.pagination = response.data.data
                this.$swal.close();
            }).catch((res) => {
                this.$swal({
                    title: getMessageError(res),
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            })
        },
        getValuesParameters() {
            let data = {}
            let dateStart = ""
            let dateEnd = ""
            let ca_id = null

            if (this.$store.getters["Users/userData"].user.role === 3) {
                ca_id = this.$store.getters["Users/userData"].user.corporate_account.id
            }

            //Dates variables filter
            if (this.rangeDate !== null) {
                dateStart = this.rangeDate.split("to")[0]
                dateEnd = this.rangeDate.split("to")[1]
            }

            let invoiceStatusValue = this.invoiceStatusSelected ? this.invoiceStatusSelected.value : null;
            let bookingStatusValue = this.selectedBookingStatusCode ? this.selectedBookingStatusCode.value : null;

            if (dateEnd == null) {
                dateStart = dateStart + " 00:00:00"
                dateEnd = dateStart + " 23:59:59";
            }

            data = {
                amountFrom: this.amountFrom,
                amountUntil: this.amountUntil,
                invoiceStatus: invoiceStatusValue,
                bookingStatus: bookingStatusValue,
                booking_id: this.booking_id,
                corporateName: this.corporateName,
                selfpayName: this.selfpayName,
                last4: this.last4,
                dateStart: dateStart,
                dateEnd: dateEnd,
                ca_id: ca_id,
                columnOrder: this.columnOrder,
                orderDesc: this.orderDesc,

            }

            return data
        },
        deleteInvoice(id) {
            this.$swal({
                title: 'Please, wait...',
                didOpen: () => {
                    this.$swal.showLoading()
                },
            })
            this.$http.post(`/admin/panel/booking/${id}/delete`)
                .then((res) => {
                    this.$swal({
                        title: res.data.message,
                        icon: 'success',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.getClientes('');

                        }
                    })

                }).catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                })
        },
        getSelfPayName(item) {
            let res = ""
            try {
                res = `${item.self_pay.name.toUpperCase()} ${item.self_pay.lastname.toUpperCase()}`
            }
            catch (err) {
            }
            return res
        },
        getPaymentType(data) {
            let res = '';
            if (data == "cc") {
                res = 'Credit Card';
            }
            if (data == "il") {
                res = 'Invoice Later';
            }
            return res;
        },
        getDriverType(item) {
            let res = ""
            try {
                res = `${item.booking.driver.driver_type}`
            }
            catch (err) {
            }
            return res
        },
        getDriverName(item) {
            let res = ""
            try {
                res = `${item.booking.driver.name} ${item.booking.driver.lastname}`
            }
            catch (err) {
            }
            return res
        },
        getTripCategory(item) {
            let res = ""
            try {
                let info = item.booking.surgery_type;
                if (info == 'cataract') {
                    info = 'Cataract surgery';
                }
                if (info == 'dentalsurgery') {
                    info = 'Dental Surgery';
                }
                if (info == 'cosmeticsurgery') {
                    info = 'Cosmetic Surgery';
                }
                if (info == 'carpaltunnel') {
                    info = 'Carpal Tunnel';
                }
                if (info == 'paininjections') {
                    info = 'Pain Injections';
                }
                if (info == 'shoulderprocedures') {
                    info = 'Shoulder Procedures';
                }
                if (info == 'softtissuerepair') {
                    info = 'Soft tissue repair';
                }
                if (info == 'notdisclose') {
                    info = 'Prefer not to disclose';
                }
                if (info == 'doctorsconsultation') {
                    info = "Doctors' consultation";
                }
                if (info == 'Standbyevent') {
                    info = 'Standby Event';
                }


                res = info.toUpperCase();
            }
            catch (err) {
            }
            return res
        },
        getPickupAddress(item) {
            let res = ""
            try {
                let fromData = JSON.parse(item.booking.from);
                res = fromData.from;
            }
            catch (err) {
            }
            return res
        },
        getPickupCity(item) {
            let res = ""
            try {
                res = item.booking.city;
            }
            catch (err) {
            }
            return res
        },
        getPickupState(item) {
            let res = ""
            try {
                res = item.booking.state;
            }
            catch (err) {
            }
            return res
        },
        getDropOffAddress(item) {
            let res = ""
            try {
                let fromData = JSON.parse(item.booking.to);
                res = fromData.to;
            }
            catch (err) {
            }
            return res
        },
        getTypeClient(data) {
            let res = "";
            let item = null;
            try {
                item = data.booking;
                if (item.self_pay) {
                    res = item.self_pay.corporate_account === null ? "Selfpay" : "CA";
                    if (item.self_pay.corporate_account === null) {
                        res = "Selfpay";
                    }
                    if (item.self_pay.corporate_account !== null) {
                        let paymentType = item.self_pay.corporate_account.corporate_account_personal_info.payment_type;

                        if (paymentType == 'il') {
                            res = "Contract Account";
                        } else {
                            res = "Corporate Account";
                        }
                    }
                }
            } catch (error) {

            }

            return res;
        },
        showDetails(booking_id) {
            this.$router.push({ name: 'details-invoice', params: { id: booking_id } })
        },
        clearFilter() {
            this.amountFrom = null
            this.amountUntil = null
            this.invoiceStatusSelected = null
            this.selectedBookingStatusCode = null
            this.booking_id = null
            this.corporateName = null
            this.selfpayName = null
            this.last4 = null;
            this.rangeDate = null
            this.getInvoicesByFilter('')
        },
        captureCharge(chargeId, bookingId) {

            this.$swal({
                title: "Do you want capture?",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                buttonsStyling: true,
            }).then((result) => {
                if (result.value === true) {
                    this.$swal({
                        title: "Please, wait...",
                        didOpen: () => {
                            this.$swal.showLoading();
                        },
                    });
                    let formData = new FormData();
                    formData.append("chargeId", chargeId);
                    formData.append("bookingId", bookingId);
                    this.$http
                        .post(`admin/panel/booking/capture_charge`, formData)
                        .then((response) => {
                            let message = response.data.data;
                            let is_charge_hold = 0;

                            if (message == 'charge_expired_for_capture') {
                                is_charge_hold = 3;
                                message = 'Charge expired for capture'
                            }
                            let currentInvoice = this.listItems.find(x => x.booking_id == bookingId);
                            currentInvoice.booking.is_charge_hold = is_charge_hold;

                            if (is_charge_hold == 3) {
                                currentInvoice.invoice_status = 'Charge expired';
                            }

                            if (is_charge_hold === 0) {
                                currentInvoice.invoice_status = 'PAID';
                            }

                            this.$swal.close();
                            this.$swal({
                                title: message,
                                icon: "info",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        })
                        .catch((error) => {
                            let message = getMessageError(error);
                            this.$swal({
                                title: message,
                                icon: "error",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        });
                }
            });
        },
        cancelCharge(chargeId, bookingId, invoiceId) {

            this.$swal({
                title: "Do you want cancel?",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                buttonsStyling: true,
            }).then((result) => {
                if (result.value === true) {
                    this.$swal({
                        title: "Please, wait...",
                        didOpen: () => {
                            this.$swal.showLoading();
                        },
                    });
                    let formData = new FormData();
                    formData.append("charge_id", chargeId);
                    this.$http
                        .post(`admin/panel/cancel_charge_stripe/${invoiceId}`, formData)
                        .then((response) => {
                            let message = "Cancelled sucefully";
                            let is_charge_hold = 4;

                            if (message == 'charge_expired_for_capture') {
                                is_charge_hold = 3;
                                message = 'Charge expired for capture'
                            }

                            let currentInvoice = this.listItems.find(x => x.booking_id == bookingId);
                            currentInvoice.booking.is_charge_hold = is_charge_hold;

                            if (is_charge_hold == 4) {
                                currentInvoice.invoice_status = 'Cancelled';
                            }

                            if (is_charge_hold == 3) {
                                currentInvoice.invoice_status = 'Charge expired';
                            }

                            this.$swal.close();
                            this.$swal({
                                title: message,
                                icon: "info",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        })
                        .catch((error) => {
                            let message = getMessageError(error);
                            this.$swal({
                                title: message,
                                icon: "error",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        });
                }
            });
        },
        changeStatusInvoiceLater(id, status) {
            let finalStatus = "paid";

            if (status == 0) {
                finalStatus = "";
            }

            this.$swal({
                title: "Do you want change the status?",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                buttonsStyling: true,
            }).then((result) => {
                if (result.value === true) {
                    this.$swal({
                        title: "Please, wait...",
                        didOpen: () => {
                            this.$swal.showLoading();
                        },
                    });
                    let formData = new FormData();
                    formData.append("id", id);
                    formData.append("status", finalStatus);
                    this.$http
                        .post(`admin/panel/set_status_invoice_later`, formData)
                        .then((response) => {

                            this.getInvoicesByFilter(this.currentUrl);
                            this.$swal.close();
                        })
                        .catch((error) => {
                            let message = getMessageError(error);
                            this.$swal({
                                title: message,
                                icon: "error",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        });
                }
            });
        },
        getStatusBooking(item) {
            let res = "";
            try {
                res = item.booking.status_code.status.toUpperCase()
            } catch (error) {

            }
            return res;
        },
        getDate(item) {
            let res = "";
            try {
                res = this.convertDate(item)
            } catch (error) {

            }
            return res;
        },
        getPickUpBookingDay(item) {
            let res = "";
            try {
                const d = new Date(item.booking.garage_time);
                res = d.getDay();
            } catch (error) {

            }
            return res;
        },
        getPickUpBookingMonth(item) {
            let res = "";
            const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

            try {
                const d = new Date(item.booking.garage_time);
                res = months[d.getMonth()];
            } catch (error) {

            }
            return res;
        },
        getPORate(item) {
            let res = "";

            try {
                res = item.booking.driver_price;
            } catch (error) {

            }
            return res;
        },
        getAdditionalCharges(item) {
            let res = "";

            try {
                res = item.booking.waiting_exceeded_amount + item.booking.wheelchair_other_equipment_rental + item.booking.parking_fees + item.booking.others_additional_fees;
            } catch (error) {

            }
            return res;
        },
        getPOpayment(item) {
            let res = "";

            try {
                res = this.getPORate(item) + this.getAdditionalCharges(item);
            } catch (error) {

            }
            return res;

        },
        getMargin(item) {
            let res = "";

            if (item.amount == 0) {
                return "";
            }
            try {
                res = (item.amount - this.getPORate(item)) / item.amount;
            } catch (error) {

            }
            return res;
        },
        getColladUser(item) {
            let res = "";

            try {
                res = item.booking.assigned_employee;
            } catch (error) {

            }
            return res;

        },
        getColladUser(item) {
            let res = "";

            try {
                res = item.booking.assigned_employee;
            } catch (error) {

            }
            return res;

        },
        getProviderRelations(item) {
            let res = item.provider_relations

            try {
                let containsNameAndEmail = res.includes("||");
                if (containsNameAndEmail == true) {
                    res = res.split("||")[0];
                }

            } catch (error) {

            }
            return res;

        },
        getPRLockedDate(item) {
            let res = "";

            try {
                res = item.booking.pr_locked_date;
            } catch (error) {

            }
            return res;

        },
        getItemsSort(column) {
            this.orderDesc = this.orderDesc == null ? true : !this.orderDesc;
            this.columnOrder = column;
            this.getInvoicesByFilter("");
        },
        setValuesFilter() {
            sessionStorage.setItem("amountFrom", JSON.stringify(this.amountFrom));
            sessionStorage.setItem("amountUntil", JSON.stringify(this.amountUntil));
            sessionStorage.setItem("invoiceStatusSelected", JSON.stringify(this.invoiceStatusSelected));
            sessionStorage.setItem("selectedBookingStatusCode", JSON.stringify(this.selectedBookingStatusCode));
            sessionStorage.setItem("booking_id", JSON.stringify(this.booking_id));
            sessionStorage.setItem("corporateName", JSON.stringify(this.corporateName));
            sessionStorage.setItem("selfpayName", JSON.stringify(this.selfpayName));
            sessionStorage.setItem("last4", JSON.stringify(this.last4));
            sessionStorage.setItem("rangeDate", JSON.stringify(this.rangeDate));
            sessionStorage.setItem("currentUrl", JSON.stringify(this.currentUrl));
        },
        getCurrentValuesFilter() {
            this.amountFrom = JSON.parse(sessionStorage.getItem("amountFrom"));
            this.amountUntil = JSON.parse(sessionStorage.getItem("amountUntil"));
            this.invoiceStatusSelected = JSON.parse(sessionStorage.getItem("invoiceStatusSelected"));
            this.selectedBookingStatusCode = JSON.parse(sessionStorage.getItem("selectedBookingStatusCode"));
            this.booking_id = JSON.parse(sessionStorage.getItem("booking_id"));
            this.corporateName = JSON.parse(sessionStorage.getItem("corporateName"));
            this.selfpayName = JSON.parse(sessionStorage.getItem("selfpayName"));
            this.last4 = JSON.parse(sessionStorage.getItem("last4"));
            this.rangeDate = JSON.parse(sessionStorage.getItem("rangeDate"));
            this.currentUrl = JSON.parse(sessionStorage.getItem("currentUrl"));
        },
        getCollabUser(data) {
            let res = data;
            try {
                let containsNameAndEmail = data.includes("||");
                if (containsNameAndEmail == true) {
                    res = data.split("||")[0];
                }

            } catch (error) {

            }
            return res;
        },
        async getStatusCodes() {
            await this.$http
                .get(`get_list_status`)
                .then((response) => {
                    let data = response.data;
                    this.optionsStatusCodes = [];
                    data.forEach(element => this.optionsStatusCodes.push({ 'title': element.status, 'value': element.status }));
                })
                .catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        async getInfo() {
            await this.getStatusCodes();
            this.getCurrentValuesFilter();
            let url = this.currentUrl == null ? "" : this.currentUrl;
            this.getInvoicesByFilter(url);
        },
        getCompany(item) {
            let res = "";
            try {
                res = item;
                if (item == "" || item == null) {
                    res = "Selfpay";
                }

            } catch (error) {

            }
            return res;
        },
        openUpdateBooking(id) {
            this.$router.push({
                name: "details-reservation",
                params: { id: id },
            });
        },
        showEditModalExtraCharge(bookingId) {
            this.currentBookingId = bookingId;
            this.$bvModal.show("modal-extra-charges-information");
        },
        calculateTotalInvoiceForClient(item) {
            let res = 0;
            let amount = item.amount;
            let extraCharge = item.total_extra_charge;

            if (amount == null || amount == "") {
                amount = 0;
            }

            if (extraCharge == null || extraCharge == "") {
                extraCharge = 0;
            }

            amount = parseFloat(amount);
            extraCharge = parseFloat(extraCharge);

            res = amount + extraCharge;

            return res;
        },
        GetExtraCharge(item) {
            return item.total_extra_charge == null ? 0 : item.total_extra_charge
        },
        async GetPdfInvoice(bookingId) {
            let res = "";

            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            await this.$http
                .get(`/download_pdf_invoice?bookingId=` + bookingId, {
                    responseType: "arraybuffer",
                    headers: {
                        Accept: "application/pdf",
                    }
                })
                .then((response) => {
                    var blob = new window.Blob([response.data], {
                        type: "application/pdf",
                    });
                    var url = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    var win = window.open();
                    win.document.write(
                        '<iframe src="' +
                        url +
                        '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
                    );
                    this.$swal.close();
                })
                .catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
            return res;
        },
        async sendEmailInvoice(bookingId, emailToCustomer = null) {
            let checkOptionEmailSelected = document.getElementsByClassName('chkEmailInvoice');
            let sendtoEmailCustomer = 0;
            let sendtoEmailCompany = 0;
            checkOptionEmailSelected.forEach(element => {

                if (element.checked == true) {
                    if (element.value == "customer") {
                        sendtoEmailCustomer = 1;
                    }
                    if (element.value == "company") {
                        sendtoEmailCompany = 1;
                    }
                }
            });

            if (emailToCustomer == 1) {
                sendtoEmailCustomer = 1;
            }

            this.$swal({
                title: 'Please, wait...',
                didOpen: () => {
                    this.$swal.showLoading()
                },
            })

            await this.$http
                .get(`/send_mail_invoice?bookingId=` + bookingId + "&sendtoEmailCustomer=" + sendtoEmailCustomer + "&sendtoEmailCompany=" + sendtoEmailCompany)
                .then((response) => {
                    this.$bvModal.hide("modal-send-email-invoice");
                    this.$swal({
                        title: response.data.data,
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                })
                .catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        openModalEmailInvoice(bookingId, companyInfo) {
            let company = this.getCompany(companyInfo);
            let isSelfpay = false;
            if (company.toUpperCase() == 'SELFPAY') {
                isSelfpay = true;
            }

            if (isSelfpay) {
                this.sendEmailInvoice(bookingId, 1);
            } else {
                this.seletedBookingId = bookingId;
                this.$bvModal.show("modal-send-email-invoice");
            }
        },
        getInvoiceExtraChargeStatus(item) {
            return GetInvoiceExtraChargeStatus(item);
        }
    },
    mounted() {
        this.getInfo();

        this.$root.$on('extracharge_was_updated', () => {
            this.getInfo();
        });
    },
    updated() {
        selectedRow();
    },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.urlPagina {
    text-decoration: none;
    color: #7367f0;
}

.urlPagina:hover {
    background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7)) !important;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
}

.list-group-item:hover {
    background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7)) !important;
    color: #fff !important;
    cursor: pointer;
}

.urlPagina::before {
    background-color: currentColor !important;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.box {
    box-shadow: 0px 14px 20px 0px rgba(143, 143, 143, 0.2) !important;
}

.my-class {
    background: #7367f0;
}

.current {
    background: #7367f0;
    color: white;
}


tr {
    cursor: pointer;
    padding: 0;
}

th {
    background: #4b4b4b !important;
    color: white !important;
    text-transform: uppercase;
}

td {
    padding: 0;
}

.table td,
.table th {
    padding: 3px;
    font-size: x-small;
    min-width: 120px;
    border: solid 1px;
    border-color: #303033;
    background: #fdfdfd;
    color: #303033;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>